<template>
  <div class="relative flex-1">
    <div class="w-full bg-blue-400 text-white p-2 font-medium text-lg">
      Select First use
    </div>
    <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg">
      Select year
    </div>
    <div class="years-container flex flex-wrap pb-2 pr-2 text-sm text-center">
      <div v-for="index in years()" v-bind:key="'delta-' + index" class="w-1/8">
        <div v-on:click="selectYear(new Date().getFullYear() - parseInt(index))" :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 border-gray-200 text-gray-500 hover:bg-blue-100 hover:border-blue-200 font-bold hover:text-blue-500 cursor-pointer', (new Date().getFullYear() - parseInt(index)) == year ? 'text-blue-600 border-blue-500 hover:border-blue-500 bg-blue-100' : '' ]">{{ (new Date().getFullYear() - parseInt(index)) }}</div>
      </div>
    </div>
    <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg">
      Select month
    </div>
    <div class="months-container flex flex-wrap pb-2 pr-2 text-sm text-center">
      <div v-for="(value, index) in moment.months()" v-bind:key="index" class="w-1/4"><div v-on:click="selectMonth(index + 1)" :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 border-gray-200 text-gray-500 hover:bg-blue-100 hover:border-blue-200 font-bold hover:text-blue-500 cursor-pointer', (index + 1) == month ? 'text-blue-600 hover:border-blue-500 border-blue-500 bg-blue-100' : '' ]">{{ value }}</div></div>
    </div>
    <div v-if="dayVisible(1)" class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg">
      Select day
    </div>
    <div class="days-container flex flex-wrap pb-2 pr-2 text-sm text-center">
      <div v-for="day in days()" v-bind:key="'day-' + day"  class="w-1/16"><div v-on:click="select(parseInt(day))" v-if="dayVisible(parseInt(day) + 1)" class="ml-2 mt-2 px-4 py-2 rounded-lg border-2 border-gray-200 text-gray-600 hover:bg-blue-100 hover:border-blue-200 font-bold hover:text-blue-500 cursor-pointer">{{ parseInt(day) + 1 }}</div></div>
    </div>
  </div>
</template>

<script>
import {daysInMonth} from "@/common";

export default {
  name: "Registration",
  data: function () {
    return {
      year: null,
      month: null,
      day: null
    }
  },
  methods: {
    years: function() {
      const arr = [...Array(24).keys()]
      console.log(arr);
      return arr;
    },
    days: function() {
      return  [...Array(daysInMonth(this.month, this.year)).keys()]
    },
    selectYear: function(year) {
      this.year = year;
    },
    selectMonth: function(month) {
      this.month = month;
    },
    select: function (day) {
      this.day = day;
      this.$store.commit('tradein/setRegistration', `${this.year}-${this.month}-${this.day}`);
      this.$store.commit('tradein/setPart', 'select-model');

    },
    dayVisible: function (day) {
      if(this.year==null) return false;
      if(this.month==null) return false;
      return day <= this.moment(`${this.year}-${this.month}`, "YYYY-MM").daysInMonth();
    }
  },
  created: function() {
    this.year = null;
    this.month = null;
    this.day = null;
  },

}
</script>

<style scoped>

</style>